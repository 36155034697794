import {generateSimpleId} from '@/app/utils/misc';

export default class Person {
  constructor() {
    this.timestamp = generateSimpleId();
    this.name = null;
  }

  applyData(data) {
    this.timestamp = data.timestamp;
    this.name = data.name;
  }

  get dataToSend() {
    return this.name;
  }

  get isValid() {
    return !!this.name;
  }
}
