import Person from './PersonModel';

export default class TableSide {
  /**
   * Constructor
   */
  constructor(addInitialPerson = true) {
    this.timestamp = new Date().getTime();
    this.people = addInitialPerson ? [new Person()] : [];
    this.title = null
  }

  applyData(data) {
    this.timestamp = data.timestamp;
    this.title = data.title;

    if (data.people) {
      this.people = data.people.map(personData => {
        const person = new Person();
        person.applyData(personData);

        return person;
      });
    }
  }

  setTitle(title) {
    this.title = title;
    return this;
  }

  /**
   * Add person
   */
  addPerson() {
    const person = new Person();
    this.people.push(person);
  }

  /**
   * Add person at given index
   * @param index
   */
  addPersonAtIndex(index) {
    const person = new Person();
    this.people.splice(index, 0, person);
  }

  /**
   * Remove person by given index
   * @param index
   */
  removePerson(index) {
    this.people.splice(index, 1);
  }

  /**
   * Count people
   * @returns {number}
   */
  get count() {
    return this.people.length;
  }

  /**
   * Get data to send
   * @returns {{comments: null, people: (null|{date: null, tables: *, newlyweds: {bride, groom}, place: null, type: null}|{name: null, table: *})[]}}
   */
  get dataToSend() {
    return {
      title: this.title,
      people: this.people.map(person => {
        return person.dataToSend;
      }),
    };
  }

  /**
   * Get if table data is valid
   * @returns {boolean|*|boolean}
   */
  get isValid() {
    return this.hasAnyPeople && this.hasValidPeople;
  }

  /**
   * Get if table has any people
   * @returns {boolean}
   */
  get hasAnyPeople() {
    return this.count > 0;
  }

  /**
   * Get if table has valid people
   * @returns {boolean}
   */
  get hasValidPeople() {
    return !this.people.some(person => {
      return !person.isValid;
    });
  }
}
