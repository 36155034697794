import TableSide from '@/app/modules/guests-lists/models/TableSideModel';

export default class Table {
  static TYPE_RECTANGULAR = 'rectangular';
  static TYPE_ROUND = 'round';

  static TYPES = [
    Table.TYPE_RECTANGULAR,
    Table.TYPE_ROUND,
  ];

  /**
   * Constructor
   */
  constructor(addInitialPerson = true) {
    this.timestamp = new Date().getTime();

    this.sides = [];
    this.type = null;
    this.makeRectangularTable();

    this.comments = null;
    this.commentsRequired = false;
    this.commentsRequiredLock = false; // TODO prevent to change comments required state
  }

  changeType(type) {
    if (type === Table.TYPE_RECTANGULAR) {
      this.makeRectangularTable();
      return;
    }

    if (type === Table.TYPE_ROUND) {
      this.makeRoundTable();
      return;
    }

    return null;
  }

  makeRectangularTable() {
    this.type = Table.TYPE_RECTANGULAR;

    const people = this.mergeSidesPeople();
    const leftSide = (new TableSide(people.length === 0)).setTitle('Lewa strona');

    if (people.length > 0) {
      leftSide.people = people;
    }

    const rightSide = (new TableSide()).setTitle('Prawa strona');

    // make 2 sides
    this.sides = [
      leftSide,
      rightSide,
    ];
  }

  makeRoundTable() {
    this.type = Table.TYPE_ROUND;

    const people = this.mergeSidesPeople();
    const newSide = new TableSide(people.length === 0);

    if (people.length > 0) {
      newSide.people = people;
    }

    this.sides = [newSide];
  }

  mergeSidesPeople() {
    return this.sides.reduce((result, side) => {
      side.people.forEach((person) => {
        result.push(person);
      });

      return result;
    }, []).filter((person) => !!person.name);
  }

  applyData(data) {
    this.timestamp = data.timestamp;
    this.type = data.type;

    if (data.sides) {
      this.sides = data.sides.map(sideData => {
        const side = new TableSide();
        side.applyData(sideData);

        return side;
      });
    }

    this.comments = data.comments;
    this.commentsRequired = data.commentsRequired;
    this.commentsRequiredLock = data.commentsRequiredLock;
  }

  /**
   * Set comments
   * @param comments
   */
  setComments(comments) {
    this.comments = comments;
  }

  /**
   * Set comments required
   * @param commentsRequired
   */
  setCommentsRequired(commentsRequired) {
    this.commentsRequired = commentsRequired;
  }

  /**
   * Get data to send
   * @returns {{comments: null, people: (null|{date: null, tables: *, newlyweds: {bride, groom}, place: null, type: null}|{name: null, table: *})[]}}
   */
  get dataToSend() {
    return {
      type: this.type,
      sides: this.sides.map(side => {
        return side.dataToSend;
      }),
      comments: this.comments
    };
  }

  /**
   * Get if table data is valid
   * @returns {boolean|*|boolean}
   */
  get isValid() {
    return this.hasValidSides && this.hasValidComments;
  }

  /**
   * Get if table has valid sides
   * @returns {boolean}
   */
  get hasValidSides() {
    return !this.sides.some(side => {
      return !side.isValid;
    });
  }

  /**
   * Get if table has valid comments
   * @returns {boolean|boolean}
   */
  get hasValidComments() {
    if (!this.commentsRequired) {
      return true;
    }

    return !!this.comments && this.comments !== '';
  }
}
